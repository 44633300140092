class Main {
  constructor() {
    this.init()
  }

  i18n() {
    this.i18next = i18next
      .use(i18nextXHRBackend)
      .use(i18nextBrowserLanguageDetector)
      .init(
        {
          load: 'languageOnly',
          debug: true,
          tName: 't', // --> appends $.t = i18next.t
          i18nName: 'i18n', // --> appends $.i18n = i18next
          handleName: 'localize', // --> appends $(selector).localize(opts);
          selectorAttr: 'data-i18n', // selector for translating elements
          targetAttr: 'i18n-target', // data-() attribute to grab target element to translate (if diffrent then itself)
          optionsAttr: 'i18n-options', // data-() attribute that contains options, will load/set if useOptionsAttr = true
          useOptionsAttr: false, // see optionsAttr
          parseDefaultValueFromContent: true, // parses default values from content ele.val or ele.text
          fallbackLng: 'en',
          // lng: 'en', // evtl. use language-detector https://github.com/i18next/i18next-browser-languageDetector
          backend: {
            loadPath: 'locales/{{lng}}.json',
            crossDomain: true
          },
          detection: {
            order: [
              'querystring',
              'cookie',
              'localStorage',
              'navigator',
              'htmlTag'
            ],
            lookupQuerystring: 'lng'
          }
        },
        function(err, t) {
          // for options see
          // https://github.com/i18next/jquery-i18next#initialize-the-plugin
          jqueryI18next.init(i18next, $)
          // start localizing, details:
          // https://github.com/i18next/jquery-i18next#usage-of-selector-function
          // $('title').localize();
          $('body').localize()
          $('body')
            .css({ opacity: 0.0, visibility: '' })
            .animate({ opacity: 1.0 }, 1500)
        }
      )

    $('.change-lang').click(function(event) {
      i18next.loadLanguages(['it', 'en'], function(err, t) {
        if (err) console.log('Error: ', err)
        var lang = event.target.id
        i18next.changeLanguage(lang, function(err, t) {
          if (err) console.log('Error: ', err)
          $(document).localize()
        })
      })
    })
  }

  theme() {}

  /**
   * All binded events
   */
  bindEvent() {}

  /**
   * UI Handlers
   */
  ui() {}

  init() {
    this.i18n()
    this.theme()
    this.ui()
    this.bindEvent()
  }
}

;(function($) {
  $(document).ready(() => {
    const www = new Main()
  })
})(jQuery) // End of use strict

// lodash trottle
function throttle(func, wait, options) {
  var context, args, result
  var timeout = null
  var previous = 0
  if (!options) options = {}
  var later = function() {
    previous = options.leading === false ? 0 : Date.now()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) context = args = null
  }
  return function() {
    var now = Date.now()
    if (!previous && options.leading === false) previous = now
    var remaining = wait - (now - previous)
    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}
